<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide *ngFor="let data of ourServiceModel ; let i = index">
            <div class="single-categories-courses-item bg1 mb-30">
                <div class="icon">
                    <!-- <img src="assets/img/services/1.svg" alt=""> -->
                    <img img [src]="path+'/WebImages/Services/'+data.image" alt="Image">

                    <!-- <i class='bx bx-code-alt'></i> -->
                </div>
                <h3>{{data.title}}</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
     
        <!-- <ng-template carouselSlide>
            <div class="single-categories-courses-item bg6 mb-30">
                <div class="icon">
                    <i class='bx bx-line-chart'></i>
                </div>
                <h3>Business Studies</h3>
                <span>49 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template> -->
    </owl-carousel-o>
</div>