<div class="health-coaching-bg-image">

    <!-- Main Banner Area -->
    <section class="health-coaching-banner">
        <div class="container-fluid">
            <div class="health-coaching-banner-slides">
                <owl-carousel-o [options]="healthCoachingBannerSlides" >
                    <ng-template carouselSlide *ngFor="let data of slidersModel ; let i = index">
                        <img img [src]="path+'/WebImages/Announcements/'+data.image" alt="Image">
                        <!-- <div [ngClass]="'bg-' + (i + 1)" [ngStyle]="{ 'background-image': 'url(' + photoUrl + slide.frontAnnouncementRecords[0]?.fileUrl + ')' }"> -->
                            
                            <div class="container">
                                <div class="gym-banner-content">
            
                                </div>
                            </div>
                        <!-- </div> -->
                    </ng-template>
            
                </owl-carousel-o>
            </div>
        </div>
    </section>

    <!-- Featured Area -->
    <section class="featured-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2 class="playfair-display">Projelerimiz</h2>
                <p>Bu projeler, kullanıcıların seyahat planlamalarını ve online alışveriş deneyimlerini kolaylaştırmak için tasarlanmıştır</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let data of projectsModel ; let i = index"> 
                    <div class="single-featured-box">
                        <div class="icon">
                            <img img [src]="path+'/Projects/'+data.image" alt="Image" style="border-radius: 15px;">

                        </div>
                        <h3 class="playfair-display">{{data.title}}</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                        <a href="{{data.link}}" target="_blank" class="link-btn">Şimdi ziyaret et...</a>
                    </div>
                </div>
                
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-heart-rate-monitor"></i>
                        </div>
                        <h3 class="playfair-display">Find Your Balance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/" class="link-btn">Start Now</a>
                    </div>
                </div> -->
            </div>
        </div>
    </section>

</div>