<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul> -->
            <h2>Hizmetlerimiz</h2>
        </div>
    </div>
</div>
<div class="courses-area ptb-100">
    <div class="container">
        <!-- <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Toplam Hizmetlerimiz 6</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Kategori</option>
                                        <option>Kategori 2</option>
                                        <option>Kategori 3</option>
                                        <option>Kategori 4</option>
                                        <option>Kategori 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let data of ourServiceModel ; let i = index">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                        <img img [src]="path+'/WebImages/Services/'+data.image1" alt="Image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <!-- <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Kategori</a>
                                        </div> -->
                                </div>
                                <div class="courses-desc" style="margin: auto;">
                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">{{data.title}}</a></h3>
                                    
                                        <p style="text-align: justify;">{{data.description}}</p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <!-- <i class='bx bx-user'></i> 10 students -->
                                            </li>
                                            <li class="courses-lesson">
                                                <!-- <i class='bx bx-calendar'></i> 14-02-2024 -->
                                            </li>
                                            <!-- <li class="courses-price">
                                                Free
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/courses-list" class="page-numbers">2</a>
                            <a routerLink="/courses-list" class="page-numbers">3</a>
                            <a routerLink="/courses-list" class="page-numbers">4</a>
                            <a routerLink="/courses-list" class="page-numbers">5</a>
                            <a routerLink="/courses-list" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">$300</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">$300</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">$300</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-style-2">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-style-2">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-style-2">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-style-2">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-style-2">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Ednuv <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog1.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog2.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog3.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog4.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog5.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog6.jpg" alt="image"></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </aside>
            </div> -->
        </div>
    </div>
</div>