<app-header-style-five></app-header-style-five>

<app-homenine-main-banner></app-homenine-main-banner>

<app-health-experience></app-health-experience>

<div class="courses-categories-area pt-100 pb-70" style="background-color: #002c1f;">
    <div class="container">
        <div class="section-title text-start">
            <!-- <span class="sub-title">Courses Categories</span> -->
            <h2 style="color: #c6a62f;">Hizmetler</h2>
            <!-- <a style="background-color: #c6a62f;border-color: #c6a62f;" routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">Hepsini Gör</span><i class="bx bx-show-alt icon-arrow after"></i></a> -->
        </div>
        <app-categories-style-two></app-categories-style-two>
    </div>
</div>

<!-- <app-features-style-two></app-features-style-two>

<app-health-experience></app-health-experience>

<app-health-program></app-health-program>

<app-homenine-courses></app-homenine-courses>

<app-health-quote></app-health-quote>

<app-homenine-events></app-homenine-events>


<app-health-subscribe></app-health-subscribe> -->


