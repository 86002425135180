import { Component, OnInit } from '@angular/core';
import { GeneralInformationService } from '../../services/generalınformation.service';
import { GeneralInformationModel } from '../../models/generalınformation';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  adresModel: GeneralInformationModel;
  phoneModel: GeneralInformationModel;
  mailModel: GeneralInformationModel;
  instagramModel: GeneralInformationModel;
  facebookModel: GeneralInformationModel;


  constructor(
    private generalInformationService: GeneralInformationService,
  ) { }

  ngOnInit(): void {
    this.adressList();
    this.phoneList();
    this.mailList();
    this.facebookList();
    this.instagramList();
  }

  adressList() {
    this.generalInformationService.getcontact("adres", 1).subscribe(data => {
      this.adresModel = data;
    })
  }
  phoneList() {
    this.generalInformationService.getcontact("telefon", 1).subscribe(data => {
      this.phoneModel = data;
    })
  }
  mailList() {
    this.generalInformationService.getcontact("mail", 1).subscribe(data => {
      this.mailModel = data;
    })
  }
  instagramList() {
    this.generalInformationService.getsocialMedia("instagram", 1).subscribe(data => {
      this.instagramModel = data;
    })
  }
  facebookList() {
    this.generalInformationService.getsocialMedia("facebook", 1).subscribe(data => {
      this.facebookModel = data;
    })
  }
}