import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OurTeamsService } from '../../services/ourteam.service';

@Component({
  selector: 'app-instructors-page-one',
  templateUrl: './instructors-page-one.component.html',
  styleUrls: ['./instructors-page-one.component.scss']
})
export class InstructorsPageOneComponent implements OnInit {
  path = environment.serverUrl;
  ourteamModel!: any;

  constructor(
    private ourteamsService: OurTeamsService
  ) { }

  ngOnInit(): void {
    this.ourteamslist();
  }


  ourteamslist() {
    this.ourteamsService.getList().subscribe(data => {
      this.ourteamModel = data;
      console.log(data)
    })
  }


}
