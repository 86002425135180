import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OurServicesService } from '../../services/ourservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories-style-two',
  templateUrl: './categories-style-two.component.html',
  styleUrls: ['./categories-style-two.component.scss']
})
export class CategoriesStyleTwoComponent implements OnInit {
	path = environment.serverUrl;


   ourServiceModel!:any;

    constructor(
		private ourServices:OurServicesService
	) { }

    ngOnInit(): void {
		this.ourServiceList();
    }
	

	ourServiceList(){
		this.ourServices.getList().subscribe(data => {
			this.ourServiceModel = data;
			console.log("Hizmetler",data)
		})
	  }

    categoriesSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 3
			},
			1200: {
				items: 4
			}
		}
    }

}