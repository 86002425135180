<footer class="footer-area" style="background-image: url(https://www.ivati-bestattungen.ch/wp-content/uploads/2020/01/ivati-desen-1.png);border-top-width: 7px;
border-top-color: #d3af27;
border-top-style: solid;">
    <div class="container">
        <div class="row" style="text-align: center;">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Ivati AG</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">
                                {{adresModel.value}}</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">{{phoneModel.value}}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:{{mailModel.value}}">{{mailModel.value}}</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="{{facebookModel.value}}" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                        <li><a href="{{instagramModel.value}}" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li> -->
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <div class="logo">
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/footer/ivati-footer-mini-1.svg" alt="image"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Büroda hizmet verdiğimiz saatler:</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Pazartesi – Cuma</a></li>
                        <li><a routerLink="/">09:00 – 12:30 | 14:00 – 17:00</a></li>
                        <!-- <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <!-- <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a> -->
            </div>
            <p>© Designed and Developed by <a href="https://vakifglobal.com/" target="_blank"><img src="https://diyanet.se/assets/img/isvecPhotos/vakifGlobalLogo.png" alt="" style="width: 130px;"></a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>


