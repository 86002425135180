import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralInformationModel } from '../../models/generalınformation';
import { GeneralInformationService } from '../../services/generalınformation.service';
import { CommunicationForm } from '../../models/communicationForm';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    form!: FormGroup;

    adresModel: GeneralInformationModel;
    phoneModel: GeneralInformationModel;
    mailModel: GeneralInformationModel;

    constructor(
        private generalInformationService: GeneralInformationService,
        private formBuilder: FormBuilder,
        private contactService: ContactService
    ) { }

    ngOnInit(): void {
        this.communicationForm();
        this.adressList();
        this.phoneList();
        this.mailList();
    }

    communicationForm() {
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            phone: ['', Validators.required]
        })
    }
    submitForm() {
        // console.log(this.form)
        if (this.form.valid) {
            const communicationForm: CommunicationForm = Object.assign(this.form.value);
            Swal.fire({
                title: 'Talebiniz gönderilsin mi?',
                showDenyButton: true,
                confirmButtonText: 'Evet', confirmButtonColor: "#238dc1",
                denyButtonText: `Hayır`, denyButtonColor: "#b47f00",
            }).then((response) => {
                if (response.isConfirmed) {
                    this.contactService.contactSend(communicationForm).subscribe((data: any) => {
                        if (data) {
                            Swal.fire({
                                title: 'Başarılı',
                                text: 'İletişim talebiniz alınmıştır...',
                                icon: 'success', iconColor: "#89dc65",
                                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
                            }).then(function (result) {
                                if (result.value) {
                                    window.location.href = 'contact';
                                }
                            })
                        }
                    });
                }
            });
        } else {
            Swal.fire({
                title: 'İletişim bilgilerini doldurun',
                icon: 'warning', iconColor: "#d4c201",
                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
            })
        }
    }

    adressList() {
        this.generalInformationService.getcontact("adres", 1).subscribe(data => {
            this.adresModel = data;
        })
    }
    phoneList() {
        this.generalInformationService.getcontact("telefon", 1).subscribe(data => {
            this.phoneModel = data;
        })
    }
    mailList() {
        this.generalInformationService.getcontact("mail", 1).subscribe(data => {
            this.mailModel = data;
        })
    }

    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var message = form.message;
        console.log(message);
    }

}