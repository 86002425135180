<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Our</li>
            </ul> -->
            <h2>Ekibimiz</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of ourteamModel ;let i = index">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/blank-profile-picture-973460_128.webp" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a>{{item.username}}</a></h3>
                        <span>{{item.categoryName}}</span>
                        <ul class="social">
                            <!-- <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                            <li><a href="{{item.twitter}}" class="email" target="_blank"><i class='bx bx-envelope'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
         
        </div>
    </div>
</div>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->