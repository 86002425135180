<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Contact</li>
            </ul> -->
            <h2>İletişim</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:{{mailModel.value}}">{{mailModel.value}}</a></p>
                    <!-- <p><a href="mailto:ednuv&#64;hello.com">ednuv&#64;hello.com</a></p> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Adres</h3>
                    <p><a href="https://goo.gl/maps/Mii9keyeqXeNH4347" target="_blank">
                        {{adresModel.value}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Telefon</h3>
                    <p><a href="tel:{{phoneModel.value}}">{{phoneModel.value}}</a></p>
                    <!-- <p><a href="tel:2414524526">+241 452 4526</a></p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Contact Us</span> -->
            <h2>Sorularınız, önerileriniz veya talepleriniz için bizimle iletişime geçebilirsiniz...</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="contact-form">
            <form id="contactForm" [formGroup]="form"  (ngSubmit)="submitForm()">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="firstName" formControlName="firstName" id="name" type="text"  class="form-control" placeholder="Adınız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="lastName" formControlName="lastName" id="lastName"class="form-control"  placeholder="Soyadınız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required id="email" name="email" formControlName="email" type="text"  class="form-control" placeholder="E-Postanız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Telefonunuz">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required name="subject" formControlName="subject" id="name" class="form-control"  placeholder="Konunuz">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required name="message" id="message" formControlName="message" cols="30" rows="5" class="form-control" placeholder="Mesajınız..."></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" ><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Mesaj Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image">
        <img src="assets/img/map.png" alt="image">
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2697.370330993706!2d8.5249439!3d47.4632124!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479075216e73bd41%3A0x63afa0cc4cc4dbbd!2sOberglatterstrasse%2035%2C%208153%20R%C3%BCmlang%2C%20%C4%B0svi%C3%A7re!5e0!3m2!1str!2str!4v1707691892539!5m2!1str!2str"></iframe>
</div>
