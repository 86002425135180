import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentsService } from 'src/app/components/services/contents.service';
import { ProjectsService } from 'src/app/components/services/projects.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-homenine-main-banner',
    templateUrl: './homenine-main-banner.component.html',
    styleUrls: ['./homenine-main-banner.component.scss']
})
export class HomenineMainBannerComponent implements OnInit {
  path = environment.serverUrl;

    slidersModel!: any;
    projectsModel!:any;

    constructor(
      private contentsService: ContentsService,
      private projectsService:ProjectsService
    ) { }

    ngOnInit(): void {
        this.sliderList();
        this.projectsList();
    }


      sliderList() {
        this.contentsService.sliderList().subscribe(newsdata => {
          this.slidersModel = newsdata;
          console.log("Slider",this.slidersModel)
        })
      }

      projectsList() {
        this.projectsService.getList().subscribe(data => {
          this.projectsModel = data;
          console.log("Projelerimiz",this.projectsModel)
        })
      }

    healthCoachingBannerSlides: OwlOptions = {
        items: 1,
        nav: true,
		loop: true,
        dots: false,
        autoplay: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        navText: [
            "<i class='flaticon-arrows'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ]
    }

}