import { Component, OnInit } from '@angular/core';
import { OurServicesService } from '../../services/ourservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-courses-list-page',
  templateUrl: './courses-list-page.component.html',
  styleUrls: ['./courses-list-page.component.scss']
})
export class CoursesListPageComponent implements OnInit {

  path = environment.serverUrl;


  ourServiceModel!: any;
  constructor(
    private ourServices: OurServicesService
  ) { }

  ngOnInit(): void {
    this.ourServiceList();
  }

  ourServiceList() {
    this.ourServices.getList().subscribe(data => {
      this.ourServiceModel = data;
      console.log("Hizmetler", data)
    })
  }

}
