<section class="distance-learning-area bg-e4feff pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="distance-learning-image">
                    <img src="assets/img/business-coaching/distance-learning.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="distance-learning-content">
                    <span class="sub-title">BUSINESS COACHING</span>
                    <h2>Increase your expertise in planning, management, and profit shape up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-self-growth"></i> Consistency</span></li>
                        <li><span><i class="flaticon-clock"></i> Improvement</span></li>
                        <li><span><i class="flaticon-ebook"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-factory"></i> Branching</span></li>
                    </ul>
                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape4"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>