<section class="business-coaching-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="business-banner-content">
                    <h1>Grow Up Your Business By The Affordable Way</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    <div class="shape"><img src="assets/img/business-coaching/circle.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="business-banner-image">
                    <img src="assets/img/business-coaching/man.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape1"><img src="assets/img/business-coaching/business-shape1.png" alt="image"></div>
    <div class="business-shape2"><img src="assets/img/business-coaching/business-shape2.png" alt="image"></div>
    <div class="business-shape3"><img src="assets/img/business-coaching/business-shape3.png" alt="image"></div>
</section>