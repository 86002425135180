<section class="experience-area ptb-100 bg-f5faf8" style="background-image: url(https://www.ivati-bestattungen.ch/wp-content/uploads/2020/01/ivati-desen-1.png);">
    <div class="container-fluid">
        <div class="row">
           
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">İvati Cenaze İşletmesi olarak zor zamanlarınızda yanınızda olmak istiyoruz.</span>
                        <!-- <img src="assets/img/inna.webp" alt="image"> -->
                    
                            <p style="color: #000;">Amacımız, vefat eden kişinin aile fertleri ve diğer yakınlarının cenaze nakliyle ilgili yerine getirilmeleri gereken görev ve yükümlülükleri üstlenmek ve vefat edeni layıkıyla son yolculuğuna uğurlamalarına yardımcı olmaktır</p>
                        <!-- <ul class="features-list">
                            <li><span><i class="flaticon-self-growth"></i> Skiled Teachers</span></li>
                            <li><span><i class="flaticon-clock"></i> Afordable Courses</span></li>
                            <li><span><i class="flaticon-ebook"></i> Efficient & Flexible</span></li>
                            <li><span><i class="flaticon-factory"></i> Lifetime Access</span></li>
                        </ul> -->
                        <!-- <a routerLink="/register" class="default-btn" style="background-color: #1c362d;border-color: #1c362d;"><i class='bx bx-paper-plane icon-arrow before' ></i><span class="label">Devamını oku...</span><i class="bx bx-paper-plane icon-arrow after"></i></a>  -->
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="col-lg-6 col-md-6" style="margin-top: 15px;">
                <div class="why-choose-us-image">
                    <img src="assets/img/about/about.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="health-coaching-shape3"><img src="assets/img/health-coaching/health-coaching-shape3.png" alt="image"></div> -->
    <!-- <div class="health-coaching-shape4"><img src="assets/img/health-coaching/health-coaching-shape4.png" alt="image"></div> -->
</section>